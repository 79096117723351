$c1:#cf0015;
$c2:#830b17;
$w1:white;
$w2:whitesmoke;
$b1:black;

@import url('https://fonts.googleapis.com/css?family=Lilita+One|Open+Sans');

html,body {
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    font-size: 85%;
}

h1, h2, h3, h4, h5 {
    font-family: 'Lilita One', cursive;
    font-size: 85%;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
	    &:hover, &:focus {
	    	background: #000;
	    	color: lighten(#000, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $c1;
	border-radius: 5px;
	background: $c1;
	color: $w1;
	padding: 2em 4em;

	&:hover {
		background: lighten(#000,10%);
		color: lighten($w1, 10%);
		box-shadow: 0px 0px 3px lighten(#000,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($w1, 5%);
	color: #000;
	border: none;

	&:hover {
		background: $w1;
		color: #000;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: #000;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken(#000,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: #000;
	color: $w1;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
//	padding: 50px 0px 20px;
	background: $w1;
	color: $c1;
    padding-top: 70px;
    
    .foot {
        
    }

	a {
        font-size: 1.5rem;
		color: sienna;
        margin: auto 3px;

		&:hover {
			color: $c2;
            text-shadow: 0px 1px 1px $b1;
            text-decoration: none;
		}
	}
}



.navlogo {
        max-width: 250px;
        @media (max-width: 767px) {
            max-width: 150px;
            margin-top: 10px;
        }
}
.nav.navbar-nav {
    margin-top: 15px;
    @media (max-width: 767px) {
        margin-top: 0;
    }
}




.navbar-default {
    background-color: $c1;
    border-radius:none !important;
    border-bottom: solid 5px $w1;    
}

.navbar {
    border-radius:0px !important;
}

.navbar .navbar-nav > li > a {
    text-align: center;
    color: $w1;
    font-size: 1.5rem;
    
    &:hover {
        background: $c2;
            text-shadow: 0px 1px 1px $b1;
            text-decoration: none;
        
    }
}

.joincon {
    .mainlogo{
        padding: 25px;
        width: 500px;
        padding-top: 75px;
        
        @media(max-width:500px){
            padding-top: 25px;
        }
    }
    
    .jtext {
        background: rgba($b1,0.8);
        border-radius: 10px;
        border: solid 1px rgba($w1,0.4);
    }
    
    h1 {
        font-size: 6rem;
            color: #a56d98;
        font-weight: 900;
    text-shadow: 0px 2px 1px $b1;
    }
    
    h2 {
        font-size: 5rem;
        text-align: center;
        color: $c2;
        font-weight: 300;
    text-shadow: 0px 2px 1px $b1;
    }
    
    h3 {
        margin-top: 10px;
        font-size: 3.5rem;
        text-align: center;
        color: $c2;
        font-weight: 300;
    text-shadow: 0px 2px 1px $b1;
    }
    @media (max-width:650px){
        h1 {
            font-size: 4rem;
            -webkit-text-stroke-width: .1px;
        }
        
        h2 {
            font-size: 3rem;
            -webkit-text-stroke-width: .1px;
        }
        
        h3 {
            font-size: 3rem;
            -webkit-text-stroke-width: .1px;
        }
    }
    
    @media (max-width:400px){
        h1 {
            font-size: 3rem;
        }
        
        h2 {
            font-size: 2.5rem;
        }
        
        h3 {
            font-size: 2.5rem;
        }
    }
}


.navbar-default .navbar-toggle {
    border-color: #fff;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-toggle {
    margin: 35px 15px 8px 0px;
}

.modal-content {
	background-color: $w1;
	color: $b1;
    border: solid .1em $c2;
}


.modal-dialog
{
 max-width: 350px;
 width: 100%;
 text-align: center;
}

.modal-header, .modal-footer
{
    background: $c2;
    color: $w1;
    border-radius: .2em;
    box-shadow: 0px 0px 3px $b1 inset;
    border: solid .1em $w1;
}

input#username
{
 margin-bottom: 20px;
}


.modal-dialog .modal-content {
    color: $c2;
}

.modal-dialog input {
    border: none;
    border-bottom: 1px solid $c2;
    text-align: center;
}


.modal-dialog input[type="submit"] {
    background:$c1; 
    border:solid 1px $c2;
}

.modal-dialog button {
    color: $b1;
}


#two {
    background: $w2;
    // @include angle(before, true);
    // @include angle(after);
    padding-top: 25px;
    
    h1 {
        font-size: 4rem;
        font-weight: 900;
        color: $c2;
        // text-shadow: 0px 1px 1px $b1;

        @media (max-width: 500px) {
            font-size: 2.5em;
        }
    }
    
    p{
        font-size: 1.5rem;
        // text-align: justify;
    }
}

#three {
    background: url(/img/beansbackground.jpg) no-repeat center center;
    background-attachment: fixed; 
    @media (max-width: 1024px) {
        background-attachment: initial !important;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 50px 0;
    
    .title{
        background: $c1;
        border-radius: 5px;
        border:solid 1px $c2;
        
        h1 {
        font-size: 4rem;
        font-weight: 900;
        color: white;
        // text-shadow: 0px 1px 1px $b1;


        @media (max-width: 500px) {
            font-size: 2.5em;
        }

        }
    }
    
}
#three2 {
    padding: 125px 0;
    background: $c2;
    @media (max-width: 1024px) {
        padding: 30px 0;
    }
    h1 {
        font-size: 4rem;
        color: white;
    }
    p {
        font-size: 1.5rem;
        color: white;
    }
}
#four {
    background: #cf0015;
    border-top: dashed 5px $w1;
    // @include angle(after);    
    .bulletpoint{
        margin-top: 25px;
        padding-top: 50px;
        
        h1{
            margin-top: 20px;
            font-size: 2.5rem;
            text-align: left;
            color: $w1;
            font-weight: 900;
            text-shadow: 0px 1px 1px $c1;
            text-align: center;
        }
        
        img {
            height: 200px;
        }
    }
    
    
}

.banner {
    background: url(../img/banner.jpg) no-repeat;
    height: 900px;
    background-size: cover;
    @media (max-width: 1024px) {
        height: auto;
        padding: 50px 0;
    }
    @media (max-width: 1199px) {
        background-position: 60% 50%;
    }
    .biglogo {
        height: 100vh;
        @media (max-width: 1024px) {
            height: auto;
        }
        @media (max-width: 1024px) and (orientation: landscape) {
            width: 50%;
        }
    }
}

a.btn.btn-large {
    @include btn1;
}

.couple {
    padding: 50px 0;
    @media (max-width: 767px) {
        padding: 15px 0;
    }
}